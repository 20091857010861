import work1 from '../images/work1.jpg'
import work2 from '../images/work2.jpg'
import work3 from '../images/work3.jpg'
import work4 from '../images/work4.jpg'
import work5 from '../images/work5.jpg'
import work6 from '../images/work6.jpg'
import perfil from '../images/perfil1.png'
import about from '../images/about1.png'
const images = {
    work1:  work1,
    work2:  work2,
    work3:  work3,
    work4:  work4,
    work5:  work5,
    work6:  work6,
    perfil: perfil,
    about: about
}

export default images;