import './App.css';
import Menu from './component/Menu';
import Header from './component/Header';
import About from './component/About';
import Skills from './component/Skills';
import Work from './component/Works';
import Footer from './component/Footer';
import Contact from './component/Contact';
function App() {
  return (
    <div>

      <Menu></Menu>

      <main class="l-main">
        <Header></Header>
        <About></About>
        <Skills></Skills>
        <Work></Work>
        <Contact></Contact>
        <Footer></Footer>
      </main>
    </div>
  );
}

export default App;