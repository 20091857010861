import React from 'react';

const Proyecto = (props) => {
  return (
    <div className="col mb-4">
      <div className="card h-100">
        <img src={props.imagen} className="card-img-top" alt={props.title} />
          <div className="card-body">
              <h5 className="card-title">{props.title}s</h5>
              <p className="card-text">{props.description}</p>
              <div className="d-flex">Technologies:
                  {props.technologies.map((tech, index) => (
                      <span className="px-2 mx-2 border border-primary rounded" key={index}>{tech}</span>
                  ))}
              </div>
          </div>
      </div>
    </div>
  );
};

export default Proyecto;
