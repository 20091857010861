import images from '../assets/images/images';

const About = () => {
    return (
        <section class="about section " id="about">
            <h2 class="section-title">About me</h2>

            <div class="about__container bd-grid">
                <div className="about__img">
                    <img src={images.about} alt=""/>
                </div>

                <div>
                    <h2 class="about__subtitle">Balmore Ramirez</h2>
                    <p class="about__text">
                        I am passionate about technology, dedicated to creating and perfecting products that effectively address my customers' problems.
                        effectively address my customers' problems. Quality and continuous improvement are fundamental pillars
                        pillars of my work.
                    </p>
                </div>
            </div>

        </section>
    );
}


export default About;
