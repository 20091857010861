import images from "../assets/images/images";
import Proyecto from "./Proyecto";
import Modal from 'react-bootstrap/Modal';
import {useState} from 'react';

const Work = () => {
    const [show, setShow] = useState(false);
    const [currentWork, setCurrentWork] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (work) => {
        setCurrentWork(work);
        setShow(true);
    };
    const works = [
        {
            image: images.work1,
            alt: 'work1',
            title: 'API RESTful',
            description: 'Travel control and people booking',
            technologies: ['Laravel', 'PostgreSQL']
        },
        {
            image: images.work2,
            alt: 'work2',
            title: 'Job title 2',
            description: 'Description of work 2',
            technologies: ['React', 'Node', 'MongoDB']
        },
        {
            image: images.work3,
            alt: 'work3',
            title: 'Job title 3',
            description: 'Description of work 3',
            technologies: ['React', 'Node', 'MongoDB']
        },
        {
            image: images.work4,
            alt: 'work4',
            title: 'Job title 4',
            description: 'Description of work 4',
            technologies: ['React', 'Node', 'MongoDB']
        },
        {
            image: images.work5,
            alt: 'work5',
            title: 'Job title 5',
            description: 'Description of work 5',
            technologies: ['React', 'Node', 'MongoDB']
        },
        {
            image: images.work6,
            alt: 'work6',
            title: 'Title of work 6',
            description: 'Description of work 6',
            technologies: ['React', 'Node', 'MongoDB']
        }
    ];
    return (
        <section className="work section" id="work">
            <h2 className="section-title">Portfolio</h2>
            <div className="work__container bd-grid">
                {works.map((work, index) => (
                    <div className="work__img" key={index} onClick={() => handleShow(work)}>
                        <img src={work.image} alt={work.alt}/>
                    </div>
                ))}
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Project</Modal.Title>
                </Modal.Header>
<Modal.Body>
    {currentWork && <Proyecto imagen={currentWork.image} title={currentWork.title}
                              description={currentWork.description} technologies={currentWork.technologies}/>}
</Modal.Body>
            </Modal>
        </section>
    )
}
export default Work;