const Footer = () => {
    return (
        <footer class="footer">
            <p class="footer__title">Balmore Ramirez</p>
            <div className="footer__social">
                <span href="#" class="footer__icon"><i class='bx bxl-facebook'></i></span>
                <span href="#" class="footer__icon"><i class='bx bxl-instagram'></i></span>
                <span href="#" class="footer__icon"><i class='bx bxl-twitter'></i></span>
            </div>
            <p class="footer__copy">&#169; Balmore Ramirez. Todos los derechos reservados</p>
        </footer>
    )
}

export default Footer;