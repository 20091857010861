import React, { useState } from 'react';

const Menu = () => {
    const [activePage, setActivePage] = useState('home');

    const handleClick = (page) => {
        setActivePage(page);
    };

    return (
        <header className="l-header">
            <nav className="nav bd-grid">
                <div>
                    <span href="#" className="nav__logo">Balmore Ramirez</span>
                </div>

                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list">
                        <li className="nav__item">
                            <a href="#home"
                               className={`nav__link ${activePage === 'home' ? 'active' : ''}`}
                               onClick={() => handleClick('home')}>
                                Home
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#about"
                               className={`nav__link ${activePage === 'about' ? 'active' : ''}`}
                               onClick={() => handleClick('about')}>
                                About me
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#skills"
                               className={`nav__link ${activePage === 'skills' ? 'active' : ''}`}
                               onClick={() => handleClick('skills')}>
                                Skills
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#work"
                               className={`nav__link ${activePage === 'work' ? 'active' : ''}`}
                               onClick={() => handleClick('work')}>
                                Portfolio
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#contact"
                               className={`nav__link ${activePage === 'contact' ? 'active' : ''}`}
                               onClick={() => handleClick('contact')}>
                               Contact
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="nav__toggle" id="nav-toggle">
                    <i className='bx bx-menu'></i>
                </div>
            </nav>
        </header>
    );
};

export default Menu;