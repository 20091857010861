import images from '../assets/images/images'

const Skills = () => {
    const skills = [
        {
            icon: 'bx bxs-data skills__icon',
            name: 'Sql/PostgreSQL/Mysql/Microsoft SQL Server',
        },
        {
            icon: 'bx bxl-css3 skills__icon',
            name: 'Html/Css3/Bootstrap/Tailwind CSS',
        },
        {
            icon: 'bx bxl-javascript skills__icon',
            name: 'Javascript/NodeJS/VueJS/ReactJS',
        },
        {
            icon: 'bx bxl-php skills__icon',
            name: 'PHP/Laravel',
        }, {
            icon: 'bx bx-git-branch skills__icon',
            name: 'Git/Github/Gitlab',
        }

    ];
    return (
        <section className="skills section" id="skills">
            <h2 class="section-title">Skills</h2>

            <div className="skills__container bd-grid">
                <div>
                    <h2 class="skills__subtitle">Professional Skills</h2>
                    <p class="skills__text">
                        During my three years of experience working in technology for different companies,
                        I have acquired a set of skills that give me a broader vision for the execution of customer
                        to execute solutions for clients.
                    </p>
                    {skills.map((skill, index) => (
                        <div className="skills__data" key={index}>
                            <div className="skills__names">
                                <i className={skill.icon}></i>
                                <span className="skills__name">{skill.name}</span>
                            </div>
                            <div className={`skills__bar ${skill.class}`}>
                            </div>            
                        </div>
                    ))}
                </div>

                <div>
                    <img src={images.work3} alt="" class="skills__img"/>
                </div>
            </div>
        </section>

    );

}

export default Skills;

